import React, { useCallback, useEffect, useState } from 'react';

import { useRanking, useMonth } from 'hooks';

import { TfiTarget } from 'react-icons/tfi';
import { FaSkull, FaFire } from 'react-icons/fa6';
import { FaDotCircle, FaTimesCircle, FaTimes } from 'react-icons/fa';


import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';

import { maps_assets } from 'assets/map';

import './styles.css';

// FOR RANKING INFO
import { Chart as ChartJS } from 'chart.js/auto';

ChartJS.defaults.color = '#a6a6a6';
ChartJS.defaults.borderColor = 'rgba(255,255,255,0.25)';

ChartJS.defaults.elements.line.borderCapStyle = 'round';
ChartJS.defaults.elements.line.tension = '0.25';


export default function Ranking() {
  const { season } = useMonth();
  const { ranking } = useRanking();

  const [open, setOpen] = useState(false);
  const [focusedPlayer, setFocusedPlayer] = useState({});

  // const [baseRanking, setBaseRanking] = useState(ranking);
  const [filteredRanking, setFilteredRanking] = useState(ranking);

  const toggleOpen = useCallback(() => {
    setOpen((state) => !state);
    // setFocusedPlayer({});
  }, []);

  const selectPlayer = useCallback((ev) => {
    const { player } = ev.currentTarget.dataset;
    const currplayer = JSON.parse(player);

    console.table(currplayer);

    setFocusedPlayer(currplayer);
    toggleOpen();


  }, []);

  const [search, setSearch] = useState('');

  useEffect(() => {
    if(!search){
      setFilteredRanking(ranking);
      return;
    }

    const lowered = search.toLowerCase();
    setFilteredRanking(ranking.filter((item) => {
      return item.name.toLowerCase().includes(lowered);
    }))
  }, [search, ranking])

  return (
    <>
      <div className="container select-none">
        {/* <div className="w-full mx-auto text-xl text-sky-300 underline font-semibold leading-10 mt-3  mb-5 px-3 lg:px-0"> */}
        <div className="w-full mx-auto text-xl text-sky-300 leading-10 mt-3  mb-5 px-3 lg:px-0">
          <div className="mt-10 text-lg md:text-2xl text-white w-full md:w-4/6 mx-auto text-center">BNE Premier | Season {season} - {new Date().getFullYear()}</div>
          {/* <div className='w-full text-center'>BNE Premier | Season {season} - {new Date().getFullYear()}</div> */}
          <div className='w-full underline text-center'>Top {ranking.length}</div>
        </div>
        <div className='flex flex-col justify-center items-center gap-x-3'>
          <span className="text-sky-300">Pesquise por jogador: </span>
          <div className='flex flex-row items-center gap-x-1 bg-white/15 rounded-sm w-52 px-2 py-1 border border-sky-400'>
            <input className='bg-transparent w-full outline-none caret-sky-400 text-sky-200' onChange={(ev) => setSearch(ev.currentTarget.value)} value={search}></input>
            {search.length > 0 && (
              <button type="button" className='text-sky-400' onClick={() => setSearch('')}><FaTimes /></button>
            )}
          </div>
        </div>
         <div className="w-full lg:w-1/2 mx-auto">
          {filteredRanking.map((p) => (
            <div key={p.steamid64} className="mx-auto leading-6 px-4 py-2 flex flex-row items-center text-white my-1 odd:bg-zinc-300/10 even:bg-zinc-400/5 sm:rounded-md cursor-pointer" data-player={JSON.stringify(p)} onClick={selectPlayer}>
              <div className="grid grid-cols-3 w-full">
                <div className="relative flex items-center col-span-2 ">
                  <div className="flex flex-row items-center relative">
                    <img src={p.rank} className="h-8 mr-2"></img>
                    <div className="text-sm sm:text-base md:text-lg lg:text-2xl flex flex-row gap-x-6">
                      <div>
                        <span>{p.name}</span>
                        <span className="text-xs flex flex-row items-center text-zinc-400">({p.totalpoints} pts)</span>
                      </div>
                      {p.win_streak >= 3 && (
                        <div className="flex items-center text-sm w-36">
                          <span className="text-orange-600 text-2xl">
                            <FaFire />
                          </span>
                          <span className="ml-2  bg-amber-700/30 text-orange-300 px-2 py-1 rounded-md">{p.win_streak} win streak</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="grid grid-cols-4 min-w-80 ">
                  <div className="text-zinc-400 flex flex-row items-center justify-center min-w-20">
                    <TfiTarget />
                  </div>
                  <div className="text-zinc-400 flex flex-row items-center justify-center min-w-20">
                    <FaSkull />
                  </div>
                  <div className="text-zinc-400 flex flex-row items-center justify-center min-w-20">
                    <FaHandshake />
                  </div>
                  <div className="text-zinc-400 flex flex-row items-center justify-center min-w-20">
                    <GiLaurelsTrophy />
                  </div>
                  <div className="flex flex-row items-center justify-center">{p.kills}</div>
                  <div className="flex flex-row items-center justify-center">{p.deaths}</div>
                  <div className="flex flex-row items-center justify-center">{p.assists}</div>
                  <div className="flex flex-row items-center justify-center">{p.win_percentage ? parseFloat(p.win_percentage.toFixed(1)) : '-'} %</div>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* START LATERAL PANEL */}
      <Dialog open={open} onClose={toggleOpen} className="relative z-50">
        <DialogBackdrop transition className="fixed inset-0 bg-zinc-900 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <DialogPanel transition className="pointer-events-auto relative w-screen max-w-xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
                <button className="absolute top-2 right-2 text-red-300 hover:text-red-600 z-50 lg:pointer-events-none lg:opacity-0 text-xl" onClick={toggleOpen}><FaTimesCircle/></button>
                {Object.keys(focusedPlayer).length > 0 && (
                <div className="flex h-full flex-col bg-zinc-800 py-6 shadow-xl text-white">
                  <div className="px-4 sm:px-6">
                    <DialogTitle className="relative flex flex-row items-center justify-start gap-2 text-3xl text-white font-semibold leading-6">
                      <img src={focusedPlayer.rank} className="inline-block h-8 mr-2"></img>
                      <span>{focusedPlayer.name}</span>
                      {focusedPlayer.win_streak >= 3 && (
                        <div className="absolute flex items-center right-2 text-sm w-36">
                          <span className="text-orange-600 text-2xl">
                            <FaFire />
                          </span>
                          <span className="ml-2  bg-amber-700/30 text-orange-300 px-2 py-1 rounded-md">{focusedPlayer.win_streak} win streak</span>
                        </div>
                      )}

                      {/* <span className="text-stone-400 text-xs">({focusedPlayer.totalpoints > 0 ? focusedPlayer.totalpoints : '-'} pts)</span> */}
                    </DialogTitle>
                  </div>
                  <div className="relative mt-2 flex-1 px-4 sm:px-6 overflow-hidden">
                    <div className="text-white h-full flex flex-col overflow-y-auto container">
                      <div className="grid grid-cols-1 sm:grid-cols-2 mx-auto mt-3 gap-2 sm:gap-3 md:gap-4 lg:gap-5">
                        <div className="box-info">
                          <div className="bi-header">Ranking Atual</div>
                          <div className="bi-content">{focusedPlayer.position}°</div>
                          <div className="bi-footer">[ {focusedPlayer.totalpoints} points ]</div>
                        </div>
                        <div className='box-info'>
                          <div className="bi-header">KDR (Kill Death Ratio)</div>
                          <div className="bi-content">{focusedPlayer.kdr}</div>
                          <div className="bi-footer flex flex-row justify-center items-center gap-2">
                            <span className="flex flex-row justify-center items-center gap-1">
                              <TfiTarget /> {focusedPlayer.kills}
                            </span>
                            <span>|</span>
                            <span className="flex flex-row justify-center items-center gap-1">
                              <FaSkull /> {focusedPlayer.deaths}
                            </span>
                          </div>
                        </div>
                        <div className='box-info'>
                          <div className="bi-header">ADR</div>
                          <div className="bi-content">{focusedPlayer.adr}</div>
                        </div>
                        <div className='box-info'>
                          <div className="bi-header">Damage per Match</div>
                          <div className="bi-content">{focusedPlayer.average_damage}</div>
                          <div className="bi-footer">[ {focusedPlayer.damage} total ]</div>
                        </div>
                        <div className='box-info'>
                          <div className="bi-header">Headshots (%)</div>
                          <div className="bi-content">{focusedPlayer.hsr ? parseFloat(focusedPlayer.hsr.toFixed(1)) : '-'}%</div>
                        </div>
                        <div className='box-info'>
                          <div className="bi-header">MVPs</div>
                          <div className="bi-content">{focusedPlayer.mvp}</div>
                          <div className="bi-footer">[ {(focusedPlayer.mvp / focusedPlayer.matches_played).toFixed(2)} / match ]</div>
                        </div>
                        <div className='is-span box-info'>
                          <div className="bi-header">Win Ratio</div>
                          <div className="bi-content">{focusedPlayer.win_percentage ? parseFloat(focusedPlayer.win_percentage.toFixed(1)) : '-'}&nbsp;%</div>
                          <div className="bi-footer">[ {focusedPlayer.won}V | {focusedPlayer.lost}D ]</div>
                        </div>

                      </div>
                      {/* <div className="grid grid-cols-1 mx-auto mt-3 sm:gap-3 md:gap-4 lg:gap-5">

                        <div className="h-20 w-56 border border-stone-500/60 rounded-md flex flex-col justify-center px-5 bg-zinc-800">
                          <div className="text-stone-400 text-xs text-center">Headshots (%)</div>
                          <div className="text-white text-3xl text-center">{focusedPlayer.hsr ? parseFloat(focusedPlayer.hsr.toFixed(1)) : '-'}%</div>
                        </div>

                        <div className="h-20 w-56 border border-stone-500/60 rounded-md flex flex-col justify-center px-5 bg-zinc-800">
                          <div className="text-stone-400 text-xs text-center">MVPs</div>
                          <div className="text-white text-3xl text-center">{focusedPlayer.mvp}</div>
                        </div>
                        <div className="h-20 w-56 border border-stone-500/60 rounded-md flex flex-col justify-center px-5 bg-zinc-800">
                          <div className="text-stone-400 text-xs text-center">ADR</div>
                          <div className="text-white text-3xl text-center"></div>
                        </div>
                        <div className="h-20 w-56 border border-stone-500/60 rounded-md flex flex-col justify-center px-5 bg-zinc-800">
                          <div className="text-stone-400 text-xs text-center">Damage per Match</div>
                          <div className="text-white text-3xl text-center">{focusedPlayer.average_damage}</div>
                        </div>
                        <div className="is-span h-20 w-56 border border-stone-500/60 rounded-md flex flex-col justify-center px-5 bg-zinc-800">
                          <div className="text-stone-400 text-xs text-center">Win Ratio</div>
                          <div className="text-white text-3xl text-center">{focusedPlayer.win_percentage ? parseFloat(focusedPlayer.win_percentage.toFixed(1)) : '-'} %</div>
                          <div className="text-stone-400 text-center mt-2 text-xs"></div>
                        </div>
                      </div> */}
                      <div className='mt-3'>
                        {focusedPlayer.recentMatches.length > 0 && (<span>Últimas {focusedPlayer.recentMatches.length} partidas</span>)}
                        {focusedPlayer.recentMatches.map((item, index) => (
                          <fieldset  className='border-2 border-stone-500/60 rounded-md my-3' key={`${focusedPlayer.steamid64}-${index}`}>
                            <legend className='px-3 flex flex-row items-center justify-center gap-x-4'>
                              <FaDotCircle className={item.won ? 'text-emerald-400' : 'text-red-500'} />
                              <img src={maps_assets[item.map.map_name].asset} className="h-8 my-0" />
                              <span>{item.map.name}</span>
                            </legend>
                            {/* <div className='px-5 py-2 flex flex-col'>
                          	  <div>
                                <div></div>
                              </div>
                            </div> */}
                            <div className="grid grid-cols-6 text-white gap-x-4 px-5 mt-2 mb-1">
                              <div className="text-center text-sm">Kills</div>
                              <div className="text-center text-sm">Deaths</div>
                              <div className="text-center text-sm">Assists</div>
                              <div className="text-center text-sm">KDR</div>
                              <div className="text-center text-sm">ADR</div>
                              <div className="text-center text-sm">HSR</div>
                              {/* <div className="text-center text-sm">Points</div> */}
                              <div className="text-center">{item.kills}</div>
                              <div className="text-center">{item.deaths}</div>
                              <div className="text-center">{item.assists}</div>
                              <div className="text-center">{item.kdr}</div>
                              <div className="text-center">{item.adr}</div>
                              <div className="text-center">{item.hsr}%</div>
                              {/* <div className="text-center">{item.totalpoints}</div> */}
                            </div>

                          </fieldset>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                )}
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
