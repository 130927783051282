import axios from 'axios';

const { REACT_APP_BNE_ENDPOINT, REACT_APP_TOKEN, REACT_APP_USER, REACT_APP_RETURN_URL } = process.env


const endpoint = axios.create({
  baseURL: REACT_APP_BNE_ENDPOINT,
  headers: {
    // authorization: token,
    // app_version: process.env.REACT_APP_VERSION,
  },
});

// Add a request interceptor
endpoint.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(`${REACT_APP_TOKEN}`);

    if(config.method === 'post'){
      config.headers['Content-Type'] = 'application/json';
    }

    if(token){
      config.headers['Authorization'] = token;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
endpoint.interceptors.response.use(
  function (response) {

    // console.table(response.status)
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
    // return response;
  },
  function (error) {
    if(error.response){
      ([401, 403].includes(error.response.status)) && (window.location.replace(REACT_APP_RETURN_URL));
      if(error.response.status === 403){
        localStorage.removeItem(`${REACT_APP_USER}`);
        localStorage.removeItem(`${REACT_APP_TOKEN}`);
      }
    }
    return Promise.reject(error.response.data);
  },
);

export default endpoint;
